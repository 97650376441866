import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommitments } from "./CommitmentSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTranslation } from "react-i18next";
import { CommitmentsCommitmentCreateResource } from "utils/types/index.types";
import { usePermissions } from "utils/permissions/usePermissions";

export const CommitmentInventoryPage = () => {
  const dispatch = useDispatch();
  const { isActionAllowed } = usePermissions();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom"]);

  //------------------Define Permissions------------------
  const createPermissionsResource: CommitmentsCommitmentCreateResource = {
    module: "Commitments",
    type: "Commitment",
    action: "create",
  };

  const canCreate = useMemo(
    () => isActionAllowed(createPermissionsResource),
    [createPermissionsResource]
  );

  const {
    isLoading: clientIsLoading,
    ft_com_Priority,
    ft_com_Associated,
  } = useSelector((state: RootState) => state.client);

  const {
    commitmentList,
    isLoading,
    error: commitmentsError,
    commitmentsById,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);

  const data = commitments.map((p: any) => {
    let newItem = Object.assign({}, p) as any;
    if (p.RecordedDate && p.RecordedDate !== null) {
      newItem.RecordedDate = ConvertDateOffset(p.RecordedDate);
    } else {
      newItem.RecordedDate = null;
    }

    newItem.RelatedParties =
      p.ContactName && p.ContactName !== null
        ? p.GroupName
          ? `${p.ContactName}, ${p.GroupName ? p.GroupName : ""}`
          : p.ContactName
        : p.GroupName;

    return newItem;
  });

  const priorityColumn = ft_com_Priority
    ? [
        {
          field: "PriorityName",
          title: t("objCom:objects.commitment.fields.priority"),
          icons: true,
          columnWidth: 60,
        },
      ]
    : [];

  const relatedColumn = ft_com_Associated
    ? [
        {
          field: "RelatedParties",
          title: t("objCom:objects.commitment.relatedparty", { count: 2 }),
          filter: "text",
        },
      ]
    : [];

  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 90,
    },
    ...priorityColumn,
    { field: "CommitmentTitle", title: t("objCom:objects.commitment.name") },
    {
      field: "CommitmentCategoryName",
      title: t("objCom:objects.commitment.fields.category"),
    },
    {
      field: "RecordedDate",
      title: t("objPlt:platformwide.fields.date"),
      format: "{0:d}",
      filter: "date",
      columnWidth: 110,
    },
    { field: "ProjectName", title: t("objPlt:objects.project.name") },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitment.fields.personresponsible"),
    },
    ...relatedColumn,
    {
      field: "ActionCount",
      title: t("objCom:objects.commitmentaction.fields.outstandingactions", {
        count: 2,
      }),
      columnWidth: 110,
    },
    {
      field: "CommitmentStatusTypeName",
      title: t("objCom:objects.commitment.fields.status"),
      columnWidth: 150,
      chips: true,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitments(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (commitmentsError) {
    return <ErrorPage errMsg={commitmentsError} />;
  }

  let CommitmentInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objCom:objects.commitment.name", { count: 2 })}
          tableData={data}
          idField="CommitmentID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={canCreate}
          showUpload={false}
          showTemplate={false}
          sortColumn={0}
          sortReverse={true}
          linkColumn={ft_com_Priority ? 2 : 1}
          searchByColumn={"CommitmentTitle"}
          searchByPlaceholder={t(
            "objCom:objects.commitment.searchcommitments",
            { count: 2 }
          )}
        />
      </div>
    );

  return <div id="Commitment-inventory-page">{CommitmentInvView}</div>;
};
