import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addStakeholderGroup,
  updStakeholderGroup,
} from "./StakeholderGroupSlice";
import { Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { IppTextField } from "components/IppTextField";
import { IppFormDivider } from "components/IppFormDivider";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchClientOrganizationTypes } from "../admin/clientOrganizationType/ClientOrganizationTypeSlice";
import { IppRating } from "components/IppRating";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { IppMultiTextField } from "components/IppMultiTextField";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { fetchUsers } from "features/users/UsersSlice";
import { User } from "api/usersAPI";
import { ClientModule } from "api/clientModuleAPI";
import { UserRole } from "api/userRoleAPI";
import { fetchOrganizationTypes } from "features/datalists/OrganizationTypeSlice";
import { ValidationSchema } from "./StakeholderGroupValidation";
import { PersonRenderOption } from "utils/renderFunctions";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { fetchLevelOfImportances } from "features/datalists/LevelOfImportanceSlice";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppDynamicPILSD } from "components/IppDynamicPILSD";
import { fetchProximityIndicatorLSDs } from "features/datalists/PILSDSlice";
import { fetchProximityIndicators } from "features/datalists/ProximityIndicatorSlice";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";

interface StakeholderGroupChildFormProps {
  setAddingItem: any;
  groupName: string;
  setNewItem: any;
  setDidSaveChild: any;
  group?: any;
}

export const StakeholderGroupChildForm = (
  props: StakeholderGroupChildFormProps
) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { setAddingItem, groupName, setNewItem, setDidSaveChild, group } =
    props;

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_lvlOfEngagement,
    ft_eng_LegalSubDiv,
    ft_eng_PILSD,
  } = useSelector((state: RootState) => state.client);

  let itemData: any;

  if (group) {
    itemData = group;
  } else
    itemData = {
      GroupName: groupName || "",
      GroupDetails: "",
      OrganizationTypeID: -1,
      LevelOfInfluence: 0,
      LevelOfInterest: 0,
      LevelOfFocus: 0,
      CountryName: "",
      HealthScore: 0,
      ClientID: clientId,
      IndigenousGroupID: -1,
      Indigenous: false,
      ProvinceState: "",
      TownCity: "",
      WebsiteAddress: "",
      PhoneNumber: "",
      GroupEmail: "",
      RelationshipOwner: null,
      LegalSubdivision: undefined,
      ProximityIndicator: undefined,
      ProximityIndicatorLSDs: [],
    };

  const {
    organizationTypeList,
    organizationTypesById,
    isLoading: organizationTypesIsLoading,
    error: organizationTypesError,
  } = useSelector((state: RootState) => state.organizationTypes);

  const organizationTypes = organizationTypeList.map(
    (id) => organizationTypesById[id]
  );

  const {
    clientOrganizationTypeList,
    clientOrganizationTypesById,
    isLoading: clientOrganizationTypesIsLoading,
    error: clientOrganizationTypesError,
  } = useSelector((state: RootState) => state.clientOrganizationTypes);

  const clientOrganizationTypes = clientOrganizationTypeList.map(
    (id) => clientOrganizationTypesById[id]
  );

  const {
    indigenousGroupList,
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (id) => indigenousGroupsById[id]
  );

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const {
    proximityIndicatorList,
    proximityIndicatorsById,
    isLoading: proximityIsLoading,
  } = useSelector((state: RootState) => state.proximityIndicators);

  const proximityIndicators = proximityIndicatorList.map(
    (id) => proximityIndicatorsById[id].ProximityIndicatorName
  );

  const { proximityIndicatorLSDList, proximityIndicatorLSDsById } = useSelector(
    (state: RootState) => state.proximityIndicatorLSDs
  );

  const proximityIndicatorLSDs = ft_eng_LegalSubDiv
    ? proximityIndicatorLSDList.map((id) => proximityIndicatorLSDsById[id])
    : [];

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const {
    userList,
    usersById,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  const {
    levelOfImportanceList,
    levelOfImportancesById,
    error: levelOfImportanceError,
    isLoading: levelOfImportanceIsLoading,
  } = useSelector((state: RootState) => state.levelOfImportances);

  const levelOfImportance = levelOfImportanceList.map(
    (id) => levelOfImportancesById[id]
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (clientOrganizationTypes.length === 0) {
          dispatch(fetchOrganizationTypes(accessToken));
          dispatch(fetchClientOrganizationTypes(accessToken));
        }
        if (indigenousGroups.length === 0) {
          dispatch(fetchIndigenousGroupsByClient(accessToken));
        }
        if (allUsers.length === 0) {
          dispatch(fetchUsers(accessToken, clientId));
        }
        dispatch(fetchProximityIndicators(accessToken));
        dispatch(fetchLevelOfImportances(accessToken));
        if (ft_eng_PILSD) {
          dispatch(fetchProximityIndicatorLSDs(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: Array.isArray(values.ProximityIndicatorLSDs)
              ? values.ProximityIndicatorLSDs.filter(
                  (pilsd: any) =>
                    pilsd.ProximityIndicator !== "" ||
                    pilsd.LegalSubdivision !== ""
                )
              : [],
          };
        }

        if (group) {
          dispatch(
            updStakeholderGroup(
              accessToken,
              values.GroupID,
              values,
              null,
              null,
              false,
              snackbarConstants
            )
          );
        } else {
          setNewItem(values.GroupName);
          dispatch(
            addStakeholderGroup(
              accessToken,
              values,
              [],
              [],
              false,
              snackbarConstants
            )
          );
        }
        setAddingItem(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleAddClose = () => {
    setDidSaveChild(false);
    setNewItem("");
    setAddingItem(false);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(itemData, stakeholdergroups),
    onSubmit: onSub,
  });

  let detailForm =
    clientIsLoading ||
    indigenousGroupIsLoading ||
    organizationTypesIsLoading ||
    clientOrganizationTypesIsLoading ||
    userIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {group
                ? t("strGen:buttons.editobj", {
                    objectname: t("objPlt:objects.group.name"),
                  })
                : t("strGen:buttons.addnewobj", {
                    objectname: t("objPlt:objects.group.name"),
                  })}
            </Typography>
          </Grid>
          <IppFormDivider
            title={t("objPlt:objects.group.headers.organizationdetails")}
          />
          <Grid item xs={6}>
            <IppTextField
              id="GroupName"
              label={t("objPlt:objects.group.fields.name")}
              required={true}
              value={formik.values.GroupName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.GroupName}
              errorsExpression={formik.errors.GroupName}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="RelationshipOwner"
              options={users}
              renderOption={(props: any, option: User) =>
                PersonRenderOption(props, option, [option.EmailAddress], "")
              }
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.RelationshipOwner;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "RelationshipOwner",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("RelationshipOwner", null);
                }
              }}
              label={t("objPlt:objects.group.fields.relationshipowner")}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.RelationshipOwner}
              touchedFunction={formik.touched.RelationshipOwner}
              textValueFunction={formik.values.RelationshipOwner}
              autoPopulate={false}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="LevelOfImportanceID"
              options={levelOfImportance}
              value={levelOfImportance.find((obj) => {
                return (
                  obj.LevelOfImportanceID === formik.values.LevelOfImportanceID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "LevelOfImportanceID",
                    newValue.LevelOfImportanceID
                  );
                } else {
                  formik.setFieldValue("LevelOfImportanceID", -1);
                }
              }}
              label={t("objPlt:objects.group.fields.importance")}
              touchedFunction={formik.touched.LevelOfImportanceID}
              errorFunction={formik.errors.LevelOfImportanceID}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) =>
                option.LevelOfImportanceName
              }
              textValueFunction={
                !levelOfImportanceIsLoading &&
                !levelOfImportanceError &&
                formik.values.LevelOfImportanceID > 0 &&
                levelOfImportancesById[formik.values.LevelOfImportanceID]
                  ? levelOfImportancesById[formik.values.LevelOfImportanceID]
                      .LevelOfImportanceName
                  : ""
              }
              toolTip={t("strGen:tooltips.group.fields.levelsofimportance")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="OrganizationTypeID"
              options={clientOrganizationTypes}
              value={organizationTypes.find((obj) => {
                return (
                  obj.OrganizationTypeID === formik.values.OrganizationTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "OrganizationTypeID",
                    newValue.OrganizationTypeID
                  );
                } else {
                  formik.setFieldValue("OrganizationTypeID", -1);
                }
              }}
              label={t("objPlt:objects.group.fields.organizationtype")}
              required={true}
              touchedFunction={formik.touched.OrganizationTypeID}
              errorFunction={formik.errors.OrganizationTypeID}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.OrganizationTypeName}
              textValueFunction={
                !organizationTypesIsLoading &&
                !organizationTypesError &&
                !clientOrganizationTypesIsLoading &&
                !clientOrganizationTypesError &&
                formik.values.OrganizationTypeID > 0 &&
                organizationTypesById[formik.values.OrganizationTypeID]
                  ? organizationTypesById[formik.values.OrganizationTypeID]
                      .OrganizationTypeName
                  : ""
              }
              toolTip={t("strGen:tooltips.group.fields.organizationtype")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="Indigenous"
              label={t("objPlt:objects.group.fields.indigenous") + "?"}
              value={formik.values.Indigenous}
              onChangeFunction={formik.handleChange}
              isEditing={true}
            />
          </Grid>

          {formik.values.Indigenous && (
            <Grid item xs={12}>
              <IppAutocomplete
                id="IndigenousGroupID"
                options={indigenousGroups}
                value={indigenousGroups.find((obj) => {
                  return (
                    obj.IndigenousGroupID === formik.values.IndigenousGroupID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "IndigenousGroupID",
                      newValue.IndigenousGroupID
                    );
                  } else {
                    formik.setFieldValue("IndigenousGroupID", -1);
                  }
                }}
                label={t("objPlt:objects.group.fields.indigenousgroupcanada")}
                isEditing={true}
                setIsEditing={null}
                optionLabelFunction={(option: any) =>
                  option.IndigenousGroupName
                }
                touchedFunction={formik.touched.IndigenousGroupID}
                errorFunction={formik.errors.IndigenousGroupID}
                textValueFunction={
                  !indigenousGroupIsLoading &&
                  !indigenousGroupError &&
                  indigenousGroups.length > 0 &&
                  formik.values.IndigenousGroupID > 0
                    ? indigenousGroupsById[formik.values.IndigenousGroupID]
                        .IndigenousGroupName
                    : ""
                }
              />
            </Grid>
          )}

          <IppLocationAutoComplete
            formik={formik}
            isEditing={true}
            setIsEditing={null}
          />

          {ft_eng_LegalSubDiv && !ft_eng_PILSD && (
            <>
              <Grid item xs={6}>
                <IppMultiTextField
                  id="LegalSubdivision"
                  label={t("objPlt:objects.group.fields.legalsubdivision")}
                  required={false}
                  options={[]}
                  noOptionsText={t("strGen:components.nooptionstext")}
                  value={formik.values.LegalSubdivision}
                  setValue={(newValue: any) =>
                    formik.setFieldValue(
                      "LegalSubdivision",
                      newValue.toString()
                    )
                  }
                  touchedFunction={formik.touched.LegalSubdivision}
                  errorFunction={formik.errors.LegalSubdivision}
                  isEditing={true}
                  setIsEditing={null}
                  multiple={true}
                  autoPopulate={false}
                  freeSolo={true}
                  toolTip={t("strGen:tooltips.legalsubdivision")}
                />
              </Grid>
              <Grid item xs={6}>
                <IppMultiTextField
                  id="ProximityIndicator"
                  label={t("objPlt:objects.group.fields.proximityindicator")}
                  required={false}
                  options={proximityIndicators}
                  noOptionsText={t("strGen:components.nooptionstext")}
                  value={formik.values.ProximityIndicator}
                  setValue={(newValue: any) =>
                    formik.setFieldValue(
                      "ProximityIndicator",
                      newValue.toString()
                    )
                  }
                  touchedFunction={formik.touched.ProximityIndicator}
                  errorFunction={formik.errors.ProximityIndicator}
                  isEditing={true}
                  setIsEditing={null}
                  multiple={true}
                  autoPopulate={false}
                  freeSolo={true}
                  toolTip={t("strGen:tooltips.proximityindicator")}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <IppTextField
              id="GroupDetails"
              label={t("objPlt:objects.group.fields.details")}
              required={false}
              value={formik.values.GroupDetails}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.GroupDetails}
              errorsExpression={formik.errors.GroupDetails}
              multiLine={true}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="WebsiteAddress"
              label={t("objPlt:objects.group.fields.website")}
              value={formik.values.WebsiteAddress}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.WebsiteAddress}
              errorsExpression={formik.errors.WebsiteAddress}
              isEditing={true}
              setIsEditing={null}
              externalLink={true}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="PhoneNumber"
              label={t("objPlt:objects.group.fields.phonenumber")}
              value={formik.values.PhoneNumber}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.PhoneNumber}
              errorsExpression={formik.errors.PhoneNumber}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={12}>
            <IppMultiTextField
              id="GroupEmail"
              label={t("objPlt:objects.group.fields.email")}
              value={formik.values.GroupEmail}
              setValue={(newValue: any) =>
                formik.setFieldValue("GroupEmail", newValue.toString())
              }
              required={false}
              options={[]}
              touchedFunction={formik.touched.GroupEmail}
              errorFunction={formik.errors.GroupEmail}
              isEditing={true}
              setIsEditing={null}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
            />
          </Grid>
          {ft_eng_PILSD && (
            <>
              <Grid xs={12} item>
                <IppFormDivider
                  title={t("objPlt:platformwide.headers.proximitylsd")}
                />
                <IppDynamicPILSD
                  id="ProximityIndicatorLSDs"
                  options={proximityIndicatorLSDs}
                  value={formik.values.ProximityIndicatorLSDs}
                  onChangeFunction={(item: any) => {
                    formik.setFieldValue("ProximityIndicatorLSDs", item);
                  }}
                  touchedFunction={formik.touched.ProximityIndicatorLSDs}
                  label=""
                  isEditing={true}
                  setIsEditing={() => {}}
                />
              </Grid>
            </>
          )}
          {ft_eng_lvlOfEngagement && (
            <>
              <IppFormDivider
                title={t("objPlt:objects.group.headers.levelsofengagement")}
              />
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInfluence"
                  label={t("objPlt:objects.group.fields.levelofinfluence")}
                  value={formik.values.LevelOfInfluence}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid>
                <IppRating
                  id="LevelOfInterest"
                  label={t("objPlt:objects.group.fields.levelofinterest")}
                  value={formik.values.LevelOfInterest}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={true}
              setIsEditing={null}
              isAdding={!group}
              showCancel={true}
              resetFunction={handleAddClose}
            />
          </Grid>
        </Grid>
      </form>
    );

  return <div id="group-child-form">{detailForm}</div>;
};
